.btn {
  display: flex;
  width: 150px;
  height: 40px;
  background: #f1f1f1;
  margin: 10px;
  border-radius: 10%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.25), 0 5px 10px rgba(0, 0, 0, 0.22);
  color: #446eac;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  justify-content: center;
  align-content: center;
  text-decoration: none;

}


@media (max-width: 640px) {
  .btn {
    width: 85px;
  }
}

.btn:hover {
  background: #446eac;
  text-decoration: none;
}

.selected {
  color: #f1f1f1;
  background: #446eac;
}

.btn div {
  font-size: 260x;
  transition: 0.2s linear;
  display: flex;
  align-items: center;

}

.btn:hover div {
  transform: scale(1.3);
  color: #f1f1f1;
}